<template>
    <div class="visit-page pb-3 mt-5 mb-5">
        <div class="container" v-html="page.content"></div>
    </div>
</template>

<script>
import { get } from '@/lib/axios';
import $ from "jquery";

export default {
    name: "KazaxstanPage",
    data: () => ( {
        type: 8,
        page: {},
    } ),
    methods: {
        async fetchData ()
        {
            await get( 'static-page/' + this.type ).then( response =>
            {
                this.page = response.data.data
            } );

            const accordion = document.getElementById( 'accordion' )
            accordion.addEventListener( 'click', function ( e )
            {
                this.classList.toggle( 'open' )
                const accordionItem = document.getElementsByClassName( 'visit-detail__block-accordion' )
                accordionItem[ 0 ].classList.toggle( 'open' )
            } )

            const that = this;

            $( 'a.visit__item-button-detail' ).on( 'click', ( e ) =>
            {
                e.preventDefault();

                that.$router.push( {
                    name: 'VisitRequestForm',
                    params: {
                        slug: 'kazakhstan'
                    }
                } )
            } );
        }
    },
    mounted ()
    {
        this.fetchData()
    },
    metaInfo ()
    {
        return {
            title: this.page.title + ` - ЛУКМАРКЕТ`,
        }
    },
}
</script>
